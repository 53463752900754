.feature_card {
    max-width: 400px;
    width: 60%;
    margin: auto;
    border: 1px solid rgb(219, 219, 240);
    height: fit-content;;
    background-color: white;
    border-radius: 15px;
    box-shadow: 3px 3px 2px 1px rgb(219, 219, 240);
    cursor: pointer;

}

/* Mobile Phones */
@media(max-width: 1200px) {
    .feature_card {
        margin: 0;
        transform: scale(.8);
    }

    .feature_card[data-side='left'] {
        margin-right: auto;
    }

    .feature_card[data-side='right'] {
        margin-left: auto;

    }
}



.feature_card__container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    min-height: 150px;
}

.feature_card__icon_wrapper {
    width: fit-content;
    height: fit-content;
    padding: 15px;
    margin: 1rem;
    background-color:#F3F3FE;
    border-radius: 50%;
    margin-bottom: 0;

}

.feature_card__content {
    padding: 20px;
    padding-top: 10px;
    text-align: start;
}

.feature_card__content > h2 {
    margin: 0;
    font-size: clamp(14px, 1.5rem, 1.5vw);
    margin-bottom: 10px;
}
.feature_card__content > p {
    margin: 0;
    font-size: clamp(12px, .8rem, 1.2vw);
    color: gray;
}