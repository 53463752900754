body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  height: fit-content;
}

* {
  scroll-behavior: smooth;
  font-family: "Noto Sans Hebrew", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar,html::-webkit-scrollbar,#root::-webkit-scrollbar,.App::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}



body[language = 'rtl'] .number_input 
{
  direction: ltr;  
}

body[language = 'rtl'] label {
  /* width: 95% !important; */
}

body[language = 'rtl'] .content_container {
  direction: rtl;
}