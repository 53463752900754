
#terms-and-privacy-model {
    position: fixed;
    top: 30;
    z-index: 9999;
    width: 80vw;
    max-height: 80vh;
    margin: auto;
    padding: min(3rem, 5vw);
    background-color: white;
    border: 1px solid black;
    border-radius: 1rem;
    inset: 0;
    box-shadow: 5px 5px 15px 5px black;
    overflow-y: scroll;
}

.section-title {
  margin-bottom: 10px;
  font-size: 1.2em;
  font-weight: bold;
}

.paragraph {
  margin-bottom: 15px;
}

.v-dialog .v-overlay__content {
  max-block-size: 100% !important;
  min-block-size: 100vh !important;
}

.modal__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
  border-radius: 12px;
  gap: 16px;
}

.close-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  color: #9ca3af;
  border-radius: 50%;
  block-size: 36px;
  cursor: pointer;
  inline-size: 36px;
  inset-block-start: 0;
  inset-inline-end: 0;
}

.modal-title h2 {
  font-size: 24px;
  color: #000;
}

.modal-title p {
  color: #4b5563;
  margin-block-end: 0;
}

.modal-input p {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #d7d4e6;
  border-radius: 8px;
  block-size: 100%;
  inline-size: 100%;
}

.modal-button span {
  font-size: 13px;
  font-weight: 600;
  color: var(--text-dark);
}

.input-checkbox input {
  position: absolute;
  block-size: 0;
  cursor: pointer;
  inline-size: 0;
  opacity: 0;
}

.input-checkbox label {
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  color: #374151;
  inline-size: calc(100% - 30px);
  inset-block-start: 0;
  inset-block-start: -1px;
  inset-inline-start: 0;
}

.input-checkbox input:checked ~ .checkmark {
  background: #11b981;
}

.input-checkbox input:checked ~ .checkmark::after {
  display: block;
}

.input-checkbox .checkmark::after {
  border: solid white;
  border-width: 0 2px 2px 0;
  block-size: 8px;
  inline-size: 4px;
  inset-block-start: 3px;
  inset-inline-start: 6px;
  transform: rotate(45deg);
}

.list {
  display: flex;
  max-height: 400px;
  column-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  inline-size: 100%;
  overflow-y: scroll;
}

.item-content h6 {
  font-size: 15px;
  font-weight: 600;
  color: #374151;
  margin-block-end: 10px;
}

.item-content p {
  font-size: 14px;
  color: #374151;
  margin-block-end: 0;
}

.item.active .item-content {
  background: #f3f4fb;
  border-color: #f3f4fb;
}

.item.active .input-checkbox label {
  font-weight: 600;
}

.modal-select svg {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-end: 10px;
  transform: translateY(-50%);
}

.modal__title > button {
  color: #fff;
  background-color: var(--text-dark);
  border-radius: 50px;
  padding-block: 10px;
  padding-inline: 32px;
  transition: var(--smooth);
}

.or {
  display: flex;
  align-items: center;
  gap: 8px;
}

.or span {
  background: var(--border-gray);
  block-size: 1px;
  inline-size: 200px;
}

.or p {
  font-style: italic;
  margin-block-end: 0;
}

.modal__title h6 {
  font-size: 14px;
  color: #374151;
}

.modal__title > button:hover {
  background-color: rgba(17, 24, 39, 80%);
}

@media (max-width: 1300px) {
  .v-dialog-xl {
    inline-size: calc(100%) !important;
  }
}

@media (max-width: 620px) {
  .modal-input {
    inline-size: 100%;
  }

  .modal-input p {
    padding-block: 0;
    padding-inline: 10px;
  }

  .or span {
    display: block;
    inline-size: 100px;
  }

  .modal-select {
    inline-size: 100%;
  }
}

@media (max-width: 480px) {
  .close-modal {
    color: #9ca3af;
    background: transparent;
  }

  .close-modal svg {
    inset-block-start: 12px;
    inset-inline-end: 10px;
  }

  .modal-title {
    inline-size: 100%;
  }

  .modal-title h2 {
    font-size: 16px;
    text-align: start;
    margin-block-end: 20px;
  }

  .v-dialog .v-overlay__content > .v-card {
    margin: -24px;
    block-size: 100%;
    overflow-y: scroll;
  }
}

.input-excel-file {
  position: absolute;
  block-size: 100%;
  inline-size: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  -moz-opacity: 0;
  opacity: 0;
}

.tc-body h1,
.tc-body h2,
.tc-body h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}
