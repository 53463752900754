.carousel {
    max-width: 100vw;
    overflow: hidden;
    width: 100%;
    position: relative;
    height: fit-content;
}

.carousel__left_arrow,
.carousel__right_arrow {
    background-color: transparent;
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    cursor: pointer;
    z-index: 2;    
}

.carousel__left_arrow {
    left: 0;
}

.carousel__right_arrow {
    right: 0;
}

.carousel__container {
    display: flex;
    margin: auto;
    width: fit-content;
    position: relative;
    justify-content: center;

}

.carousel__container > * {
    min-width: 0px !important;
    transition: transform 500ms ease !important;

}

@keyframes animationMiddle {
    from {
        transform: scale(.6);
    }
    to {
        transform: scale(.8);
    }
}

@keyframes animationEdges {
    from {
        transform: scale(.8);
    }
    to {
        transform: scale(.6);
    }
}

.carousel__container > *:nth-child(2) {
    position: absolute;
    margin: auto;
    inset: 0;
    max-width: 300px;
    z-index: 2;
    background-color: white;
    animation: forwards animationMiddle 500ms;
}
.carousel__container > *:nth-child(2).animate {
    animation: forwards animationMiddle 500ms;
}

.carousel__container > *:first-child,
.carousel__container > *:last-child {
    opacity: .5 !important;
    background-color: #4538CA;
    transform: scale(.6);
    color: white;
    max-width: 300px;
}

.recommendation_card.animate {
    animation: forwards animationEdges 500ms ease;
}