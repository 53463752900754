.recommendation_card {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    text-align: start;
    border: 1px solid rgb(219, 219, 240);
    padding: 10px;
    border-radius: 10px;
    background-color: transparent;
    font-family: Inter;
    min-width: 300px
}

.recommendation_card > *:last-child {
    align-self: flex-end;
}

.recommendation_card__wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.recommendation_card__wrapper > h3 {
    font-weight: 600;
    font-size: clamp(14px, 1.2rem , 2vw);
}

.recommendation_card__wrapper > h3 > strong {
    font-weight: bold;
}

.recommendation_card__wrapper > p {
    font-weight: 300;
    font-size: clamp(14px, .8rem , 1.5vw);
}


/* Mobile Phones */
@media (max-width: 576px) {
    .recommendation_card {
        transform: scale(.5);
    }   

}

/* Small Tablets */
@media (min-width: 577px) and (max-width: 768px) {
    .recommendation_card {
        transform: scale(.6);
    }   
}


/* Large Tablets and Small Laptops */
@media (min-width: 769px) and (max-width: 992px) {
    .recommendation_card {
        transform: scale(.7);
    }   
}

/* Medium Laptops and Desktops */
@media (min-width: 993px) and (max-width: 1200px) {
    .recommendation_card {
        transform: scale(.8);
    }   
}

/* Large Laptops and Desktops */
@media (min-width: 1201px) {
    
}

/* Landscape Orientation */
@media (orientation: landscape) {
    
}

/* High DPI (Retina) Displays */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    
}