#discount_timer {
    height: inherit;
    width: fit-content;
}

#discount_timer__wrapper {
    height: inherit;    
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: red;
    gap: min(2vw, 15px);
}

.discount_timer_container {
    height: inherit;
    display: flex;
    justify-content: space-between;
    font-size: xx-large;
    gap: min(1vw, 10px);
}

.discount_timer__space {
    height: inherit;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: clamp(14px, 2rem, 2vw);

}

.discount_timer_container > * {
    border-bottom: 1px solid rgb(219, 219, 240);
    box-shadow: inset 0px 0px 1px 1px rgb(219, 219, 240);;
    border-radius: 5px;
    padding: 1rem;
    font-size: clamp(14px, 2rem, 2vw);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background-color: white;

}