.dropdown_model_out {
    z-index: 1;
    position: fixed;
    width: 1000vw;
    height: 100vh;
    left: 0;
    top: 0;
    
}

.dropdown_model {
    min-width: fit-content;
    min-height: fit-content;
    position: fixed;
    z-index: 2;
    top: 70px;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(219, 219, 240);
    padding: clamp(2rem, 10vw, 5rem);
    left: 5px;
    border-bottom-right-radius: 1rem;
    background-color: white;
}