/* div:hover,nav:hover,main:hover,header:hover, ul:hover {
    box-shadow: inset 0px 0px 0px 1px;
}
 */

#navbar {
    position: relative;
    z-index: 2;
    height: 70px;
}

#navbar_wrapper_fixed {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100%;
    height: inherit;
    border-bottom: 1px solid rgb(219, 219, 240);
    gap: 5px;
    backdrop-filter: blur(30px);
    text-shadow: 0px 0px 2px rgba(255, 255, 255, 1);

}

#navbar__left_side_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: inherit;
}

#navbar__middle_container {
    display: flex;
    flex: 1;
    align-items: center;
    position: relative;
    height: inherit;
    justify-self: start;
    gap: 20px;
}

#navbar__right_side_container {
    display: flex;
    flex: .3;
    align-items: center;
    justify-content: center;
    min-width: fit-content;
    height: inherit;
    padding: 0px 10px;
    gap: 10%;

}

#navbar__right_side_container button {
    font-size: clamp(12px, 2vw, .8rem);
}

#language_controller {
    font-size: clamp(20px, 2rem, 3vw);
    cursor: pointer;
}

#dropdown_menu_controller {
    margin-right: auto;
    font-size: clamp(20px, 2rem, 3vw);
    cursor: pointer;
}

#navbar__list_items_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: inherit;
    margin: 0;
    padding: 0;
    font-family: Inter;
    list-style: none;
    gap: 5%;
}

#navbar__list_items_container li {
    cursor: pointer;
}

#navbar__list_items_container li:hover {
    text-shadow: 1px 0px 0px rgba(0, 0, 0, 0.5);
    text-decoration: underline;
}

#navbar-button-book-demo {
    width: 15vw;
    min-width: 90px;
    max-width: 150px;
    height: 40px;
    color: white;
    background: #397EE6;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);

}

#navbar-button-sign-in {
    width: 15vw;
    min-width: 70px;
    max-width: 100px;
    height: 40px;
    color: white;
    background: #000000;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.dropdown_model li{
    padding: clamp(1.5rem, 5.5vw, 3rem) clamp(1rem, 5vw, 2.5rem);
    list-style: none;
    transition: .5s;

}

.dropdown_model li:hover{
    border-bottom: 1px solid rgb(219, 219, 240);
    list-style: none;
}

/* ----------------------- End of navbar feature ------------------------------*/



#main_content_page {
    position: relative;
    padding: 2rem 0rem;
}

#welcome_section__header {
    max-width: min(90%, 600px);
    margin: auto;
}

#welcome_section__header > * {
    width: inherit;
    font-family: Inter;
}

#welcome_section__header__logo_container {
    margin-right: 3%;
    margin-bottom: 40px;
}

#welcome_section__header__logo_container img {
    width: min(50vw, 200px);
}

#welcome_section__header__headline h1 {
    margin-bottom: 0px;
    font-size: clamp(35px, 3rem, 3vw);
}
#welcome_section__header__headline h2 {
    font-size: clamp(20px, 1.65rem, 2vw);
}

#welcome_section__header__count_down_component_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 90%;
    margin: auto;
}

#welcome_section__header__main_content_minimized_lead_form {
    display: flex;
    justify-content: space-evenly;
    width: min(75vw, 550px);
    height: inherit;
    margin: auto;
    margin-top: 50px;
    padding: 10px;
    font-family: Inter;
    background-color: white;
    border: 1px solid rgb(219, 219, 240);
    border-radius: .4rem;
    gap: 20px;
}

body[language='ltr'] #welcome_section__header__main_content_minimized_lead_form__full_name_box {
    border-right: 1px solid rgb(219, 219, 240);
}
body[language='rtl'] #welcome_section__header__main_content_minimized_lead_form__full_name_box {
    border-left: 1px solid rgb(219, 219, 240);
}

#welcome_section__header__main_content_minimized_lead_form__full_name_box,#welcome_section__header__main_content_minimized_lead_form__phone_number_box {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    gap: 10px;
}


#welcome_section__header__main_content_minimized_lead_form__full_name_box:hover > input, #welcome_section__header__main_content_minimized_lead_form__phone_number_box:hover > input {
    border: none;    
    border-bottom: 1px solid rgb(219, 219, 240);
}

#welcome_section__header__main_content_minimized_lead_form__full_name_box > input, #welcome_section__header__main_content_minimized_lead_form__phone_number_box > input {    
    width: 90%;
    font-size: clamp(14px, 1rem, 1.5vw);
    border: none;
}

#welcome_section__header__main_content_minimized_lead_form__full_name_box > label, #welcome_section__header__main_content_minimized_lead_form__phone_number_box > label {    
    width: 90%;
    width: fit-content;
    font-size: clamp(10px, .7rem, 1vw);
    border: none;
}
#welcome_section__header__main_content_minimized_lead_form__full_name_box > input:focus, #welcome_section__header__main_content_minimized_lead_form__phone_number_box > input:focus {
    border: none;
    border-bottom: 1px solid rgb(219, 219, 240);
    outline: none;
  
    
}

#welcome_section__header__main_content_minimized_lead_form__request_box {
    justify-content: flex-start;
    width: fit-content;
}

#welcome_section__header__main_content_minimized_lead_form__phone_number_box {
    flex: 1;
}

#welcome_section__header__main_content_minimized_lead_form__request_box > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    padding: 15px 30px;
    font-size: 16px;
    color: white;
    background-color: #4538CA;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 1px .5px black;
    cursor: pointer;
    transition: 10ms;
}
#welcome_section__header__main_content_minimized_lead_form__request_box > button:active {
    box-shadow: 0px 0px 0px 0px black;
}

#welcome_section__header__main_content__wrapper_slider_component {
    width: 100%;
    margin-top: 4rem;
}

#discount__post {
    position: relative;
    width: 70%;
    margin: auto;
}

/* Mobile Phones */
@media(max-width: 768px) {
    #discount__post {
        width: 100%;
        height: 500px;
    }
}

#discount_post__hero {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    overflow: hidden;
}

#discount_post__hero > img {
    width: 100%;
    min-width: 500px;
    height: 500px;
    min-height: 100%;
    object-fit: cover;
    object-position: top right;
}

#discount_post__content {
    max-width: 500px;
    padding: 4rem;
    text-align: start;
    color: white;
}

@media(max-width: 768px) {
    #discount_post__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 500px;
        padding: 0rem;
        padding-top: 3rem;
        text-align: center;
        color: white;
    }
    
}

#discount_post__content__countdown_discount {
    padding: 20px 0px;
}

#discount_post__content__like_discount > button {
    width: 300px;
    height: fit-content;
    margin-top: 50px;
    padding: 1rem;
    font-size: clamp(14px, 1rem, 2vw);
    color: white;
    background-color: #4538CA;
    border: none;
    border-radius: 5px;
    box-shadow: .5px .5px 1px .1px white;
    cursor: pointer;
    transition: 1s;
    text-align: center;
}

#discount_post__content__like_discount > button:active {
    box-shadow: 1px 1px 1px 1px white;
}

#discount_post__content__description {
    max-width: 350px;
    word-wrap: break-word;
}

#discount_post__content__headline {
    max-width: 350px;
}

/* -------------------- End Of A Discount Post ------------------------------- */


@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#bondi_feature_presentation {
    display: flex;
    align-items: center;
    justify-content: space-between;


}


/* Small Tablets */
@media (min-width: 577px) and (max-width: 768px) {
    #bondi_feature_presentation {
        width: 54vw;
        transform: scale(.8);
    }
}



#bondi_feature_presentation__title_container {  
    margin-top: 4rem;
    font-family: Inter;

}
#bondi_feature_presentation__title_container > h1 {
    font-size: clamp(40px, 4rem, 5vw);
    text-align: center;
}

#bondi_feature_presentation__title_container > p {
    max-width: 50%;
    margin: auto;
    font-size: clamp(14px, 1rem, 1.5vw);
    text-align: center;
    color: gray;
}

/* Mobile Phones */
@media(max-width: 768px) {
    #bondi_feature_presentation__title_container > p {

        max-width: 90%;
    }
}

#bondi_feature_presentation__title_colorful_span {
    color: #4538CA;
}

#bondi_feature_presentation__smartphone_view_port_component {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 0%;
    max-width: 200px;
}

#bondi_feature_presentation__smartphone_view_port_component_children_content {
    width: 100%;
    height: 100%;
}

#bondi_feature_presentation__smartphone_view_port_component_children_content::-webkit-scrollbar {
    width: 1px;
}

#bondi_feature_presentation__smartphone_view_port_component_children_content[data-isLoading=true] > img {
    width: 30%;
    animation: spin 2s linear infinite;
}

#bondi_feature_presentation__smartphone_view_port_component_children_content[data-isLoading=false] { 
    overflow-y: scroll;
}

#bondi_feature_presentation__smartphone_view_port_component_children_content[data-isLoading=true] {
    display: flex;
    align-items: center;
    justify-content: center;
}

#bondi_feature_presentation__smart_phone_view_port_component_background {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: -1;
    margin: auto;
    inset: 0;
}

.bondi_feature_presentation__list_container {
    width: 100%;
    /* margin: 1rem; */
    min-width: fit-content;
    height: 100%;
    min-height: 700px;
}

.bondi_feature_presentation__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: auto;
    margin: 0;
    padding: 0;
    gap: 3rem;
    transform: scale(.85);
    max-width: 50vw;
    
}

/* ------------------- End Of a Feature -------------------------- */

#join_bondi_form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    gap: 2rem;
}

#join_bondi_form__title_container {  
    margin-top: 4rem;
    font-family: Inter;



}
#join_bondi_form__title_container > h1 {
    font-size: clamp(40px, 4rem, 5vw);
    text-align: center;
}

#join_bondi_form__title_colorful_span {
    color: #4538CA;
}

#join_bondi_form__form_container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 2px;
    background: radial-gradient(circle farthest-corner at top left,#4538CA, #FF0094, #FEA6AE, #F6CD01, #4538CA);
    border-radius: 20px;
    
}

#join_bondi_form__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: min(400px, 30vw);
    max-width: 400px;
    height: min(400px, 30vw);
    max-height: 400px;
    background-color: white;
    border-radius: 20px;
    gap: 2rem;
    
}   

#join_bondi_form__form > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 60%;
    gap: 15px;
}

.join_bondi_form__form__label_container > label {
    font-size: clamp(14px, 1rem, 1.1vw);
    text-shadow: 0px 0px 2px rgba(255, 255, 255, 1);
}

.join_bondi_form__form__label_container > input {
    font-size: clamp(16px, 1.2rem, 1.4vw);
    border: none;
    border-bottom: 1px solid rgb(219, 219, 240);
    outline: none;
}

.join_bondi_form__recommendations_list_container {
    min-height: 700px;
}

.join_bondi_form__recommendations_list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: inherit;
}

.join_bondi_form__recommendations_list > * {
    max-width: 60%;
}

.join_bondi_form__recommendations_list[data-side='left'] > *:first-child{
    align-self: flex-start;
}
.join_bondi_form__recommendations_list[data-side='left'] > *:last-child {
    align-self: flex-end;
}

.join_bondi_form__recommendations_list[data-side='right'] > *:first-child{
    align-self: center;
}
.join_bondi_form__recommendations_list[data-side='right'] > *:last-child {
    align-self: flex-end;
}

/* Mobile Phones */
@media (max-width: 576px) {
    #join_bondi_form__form {
        width: 90vw;
        height: 90vw;
    }
}

/* Small Tablets */
@media (min-width: 577px) and (max-width: 992px) {
    #join_bondi_form__form {
        width: min(500px, 80vw);
        height: min(500px, 80vw);
    }
}

/* Medium Laptops and Desktops */
@media (min-width: 993px) and (max-width: 1200px) {
    #join_bondi_form__form {
        width: min(600px, 60vw);
        height: min(600px, 60vw);
    }
}
@media(max-width: 1200px) {
    #join_bondi_form__form_container {
        margin: auto;
    }
}


/* End of Feature */


#contact_whatsapp {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 700px;
    min-height: 700px;
}

#contact_whatsapp__container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #f6f7ff;
}
#contact_whatsapp__container__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#contact_whatsapp > * {
    min-width: 50%;
    margin-top: 4rem;
}


#contact_whatsapp__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 60%;
    margin: auto;
    text-align: start;
    gap: 2rem;
}

@media(max-width: 992px) {
    #contact_whatsapp__content {
        max-width: 90%;
        padding: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center !important;
    }
}

/* Mobile Phones */
@media(max-width: 768px) {
    #contact_whatsapp__content {
        max-width: 100%;
        padding: 2%;
    }
}




#contact_whatsapp__phone_view_port_wrapper > * {
    transform: scale(.9) !important;
}

#contact_whatsapp__content__header_wrapper {
    max-width: 65%;
}
#contact_whatsapp__content__header_wrapper > h1 {
    font-size: clamp(32px, 2.3rem, 4vw);
    text-wrap: nowrap;

}

#contact_whatsapp__content__header_wrapper > p {
    font-size: clamp(16px, 1.2rem, 2vw);
    font-weight: 300;

}

#contact_whatsapp__content__contact_button_container > button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;;
    width: max(250px, 25vw);
    max-width: 395.89px;
    height: 62px;
    padding: 19px 48px;
    font-size: clamp(12px, 1rem, 2vw);
    color: white;
    background: #196D3A;
    border: none;
    border-radius: 5px;
    gap: 12px;
    box-shadow: 0px 1px 2px rgba(27, 36, 44, 0.12);
    text-wrap: nowrap;


}

#contact_whatsapp__content__contact_button_container > button > svg {
    min-width: 24px;
}

/* End of a feature */

#footer {
    width: 100%;
    height: min(20vw, 100px);
    margin-top: 10vw;
    background-color: #050C27;
}

#footer-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 100%;
    margin: auto;
    gap: 10px;
}

.footer-content-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: fit-content;
    min-height: 30px;
    font-size: clamp(.7rem, 2vw, 1.5rem);
    color: white;
    white-space: nowrap;
}

.footer-content-container:last-child {
    justify-content: flex-end;
    gap: 1.5rem;

}
.footer-content-container:first-child {
    justify-content: flex-start;
}