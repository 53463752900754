.phone_view_port__smartphone {
    position: relative;
    width: 360px;
    height: 640px;
    margin: auto;
    border: 16px black solid;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;
    transform: scale(.75);
  }

@media (max-width: 576px) {
  .phone_view_port__smartphone {
    transform: scale(.35);
  } 

  #bondi_feature_presentation__smart_phone_view_port_component_background {
    transform: scale(.40);
  }
}

/* Small Tablets */
@media (min-width: 577px) and (max-width: 768px) {
  .phone_view_port__smartphone {
    transform: scale(.45);
  } 

  #bondi_feature_presentation__smart_phone_view_port_component_background {
    transform: scale(.60);
  }
}

/* Large Tablets and Small Laptops */
@media (min-width: 769px) and (max-width: 992px) {
  .phone_view_port__smartphone {
    transform: scale(.55);
  } 

  #bondi_feature_presentation__smart_phone_view_port_component_background {
    transform: scale(.70);
  }
}

/* Medium Laptops and Desktops */
@media (min-width: 993px) and (max-width: 1200px) {
  .phone_view_port__smartphone {
    transform: scale(.65);
  } 
  #bondi_feature_presentation__smart_phone_view_port_component_background {
    transform: scale(.80);
  }

}

  
  
  /* The horizontal line on the top of the device */
  .phone_view_port__smartphone:before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
  }
  
  /* The circle on the bottom of the device */
  .phone_view_port__smartphone:after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 50%;
  }
  
  /* The screen (or content) of the device */
  .phone_view_port__smartphone .phone_view_port__content {
    width: 360px;
    height: 640px;
    background: white;
  }