#slider__container {
    position: relative;
    width: 100%;
    margin: auto;
    overflow-x: hidden;
}

/* Mobile Phones */
@media (max-width: 576px) {
    
}

/* Medium Laptops and Desktops */
@media (min-width: 993px) {
    #slider__container {
        margin-top: 8rem;
        transform: scale(130%);
    }
}



@keyframes SlideAndFadeIn {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    
    50% {
        opacity: 1;
        transform: translateX(0%);
    }

    100% {
        opacity: 0;
        transform: translateX(-100%);
    }

}

.slider_img_fade_in {
    opacity: 0;
    animation: SlideAndFadeIn 4s ease infinite;
}